import { createSlice } from '@reduxjs/toolkit';

const mspSlice = createSlice({
  name: 'msp',
  initialState: false,
  reducers: {
    addMspdata: (state, action) => {
      return action.payload;
    },
    editMspdata: (state, action) => {
     
        const index = state.findIndex((item) => (item.id == action.payload.id));
        if (index >= 0) {
          state[index] = action.payload
        }
      
    },
    removeMspdata: (state, action) => {
      return false
    },
  },
});

export const mspReducer = mspSlice.reducer;

export const {
  addMspdata,
  editMspdata,
  removeMspdata,
} = mspSlice.actions;