import { createSlice } from '@reduxjs/toolkit';

const memberSlice = createSlice({
  name: 'member',
  initialState: false,
  reducers: {
    addMember: (state, action) => {
      return action.payload;
    },
    editMember: (state, action) => {
        const index = state.findIndex((item) => (item.id == action.payload.id));
        if (index >= 0) {
          state[index] = action.payload
        }
    },
    removeMember: (state, action) => {
      return false      
    },

  },
});

export const memberReducer = memberSlice.reducer;

export const {
  addMember,
  editMember,
  removeMember,
} = memberSlice.actions;