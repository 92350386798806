import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: false,
  reducers: {
    addAuth: (state, action) => {
      return action.payload
    },
    removeAuth: (state, action) => {
      // remove the current entry if any
      return false
    },
    addImpersonateMSP: (state, action) => {
      return {
        ...state,
        impersonate: {
          ...state.impersonate,          
        },
        msp: action.payload.msp
      }
    },
    removeImpersonateMSP: (state, action) => {
      return {
        ...state,
        impersonate: {
          ...state.impersonate,
        },
        msp: false

      }
    },
    addImpersonateTenant: (state, action) => {
      return {
        ...state,
        impersonate: {
          ...state.impersonate,
          tenant_id: action.payload.xtenant,
          tenant_logo: action.payload.tenant.logo,
        },
        token: action.payload.token,
        xtenant: action.payload.xtenant,
        tenant: action.payload.tenant

      }
    },
    removeImpersonateTenant: (state, action) => {
      return {
        ...state,
        token: action.payload.token,
        xtenant: false,
        impersonate: {
          ...state.impersonate,
          tenant_id: false,
          tenant_logo: false,
        },
      }
    },
    addImpersonateMember: (state, action) => {
      return {
        ...state,
        impersonate: {
          ...state.impersonate,
        },
        member: action.payload.member,
      }
    },

    removeImpersonateMember: (state, action) => {
      return {
        ...state,
        impersonate: {
          ...state.impersonate,
        },
        member: false
      }
    }
  },
});

export const authReducer = authSlice.reducer;

export const {
  addAuth,
  removeAuth,
  addImpersonateMSP,
  removeImpersonateMSP,
  addImpersonateMember,
  removeImpersonateMember,
  addImpersonateTenant,
  removeImpersonateTenant
} = authSlice.actions;