
import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { useRouter } from 'next/router';

/**
 * our deeplink handler
 * @returns 
 */
export const DeepLink = () => {
    let router = useRouter();
    useEffect(() => {
        App.addListener('appUrlOpen', (event) => {
            // Example url: https://sdstaging.surge.sh/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('.surge.sh').pop();
            if (slug) {
                router.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
}
