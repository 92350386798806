import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: false,
  reducers: {
    addUser: (state, action) => {
      return action.payload ;
    },
    removeUser: (state, action) => {
      return false
    },
    editUser: (state, action) => {
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        notify_email: action.payload.notify_email,
        notify_native: action.payload.notify_native
      }
    },
    updateToken: (state, action) => {
      // see if we already have an entry in our user store
      state.token = action.payload.token
    },
    updateOnboard: (state, action) => {
      // see if we already have an entry in our user store
      state.onboarded = action.payload.onboarded,
      state.onboarded_at = action.payload.onboarded_at
    }
  },
});

export const userReducer = userSlice.reducer;

export const {
  addUser,
  removeUser,
  editUser,
  updateToken,
  updateOnboard
} = userSlice.actions;