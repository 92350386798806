import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    removeLoading: (state, action) => {
      return false
    },
    setLoading: (state, action) => {
      return action.payload.progress
    },
    errorLoading: (state, action) => {
      return "error"
    },
  },
});

export const loadingReducer = loadingSlice.reducer;

export const {
  removeLoading,
  setLoading,
  errorLoading,
} = loadingSlice.actions;