import { createSlice } from '@reduxjs/toolkit';

const tenantSlice = createSlice({
  name: 'tenant',
  initialState: false,
  reducers: {
    addTenantdata: (state, action) => {
      return action.payload;
    },
    editTenantdata: (state, action) => {
     
        const index = state.findIndex((item) => (item.id == action.payload.id));
        if (index >= 0) {
          state[index] = action.payload
        }
    
    },
    removeTenantdata: (state, action) => {
      return false
    },
  },
});

export const tenantReducer = tenantSlice.reducer;

export const {
  addTenantdata,
  editTenantdata,
  removeTenantdata,
} = tenantSlice.actions;