import '../public/assets/css/globals.scss';
import { Provider } from 'react-redux';       // Importing Provider
import store from '../redux/store';           // Importing redux store
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { useTheme } from 'next-themes'

import { ThemeProvider } from 'next-themes'
import { set_statusbar } from '../lib/statusbar';
import Head from 'next/head';
import { DeepLink } from '../lib/deeplink';



let persistor = persistStore(store);

function MyApp({ Component, pageProps }) {

  // set the status bar to match the theme
  const { theme } = useTheme()
  set_statusbar(theme)

  return (
    <>
      <Head>
        <title>Shadow Detect</title>
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" type="image/png" href="/assets/images/favicon.ico" />
        <link rel="apple-touch-icon" href="/assets/images/AppIcon-512@2x.ico" />
        <meta name="theme-color" content="#202124" />
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider enableSystem={false}>
            <DeepLink/>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
 
    </>
  )
}

export default MyApp
